.time-period-selector {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto 1fr;

  select-dropdown,
  input-dropdown {
    border: 1px solid var(--nylas-base-200);
    border-radius: var(--nylas-border-radius-2x);
  }
  select-dropdown {
    width: 116px;
  }
  input-dropdown {
    width: 84px;
    display: flex;
    align-items: center;
  }

  select-dropdown#time-period::part(sd_dropdown-button) {
    width: 100%;
  }
  input-dropdown::part(id_dropdown) {
    width: inherit;
    height: 100%;
  }
  input-dropdown#time-number::part(id_dropdown-input) {
    border: none;
    border-radius: var(--nylas-border-radius-2x);
  }
  input-dropdown::part(id_dropdown-input) {
    padding: 17px;
    gap: 1rem;
  }
  input-dropdown::part(id_dropdown-content) {
    width: 100%;
    max-height: 200px;
  }
  select-dropdown::part(sd_dropdown) {
    width: inherit;
  }
  select-dropdown::part(sd_dropdown-button) {
    padding: 1rem;
    gap: 1rem;
    justify-content: space-between;
  }
  select-dropdown::part(sd_dropdown-content) {
    width: 100%;
    max-height: 200px;
  }
}
