import { debug } from '@/utils/utils';
import { Element, Event, EventEmitter } from '@stencil/core';
import { Component, h, Listen, Prop, State } from '@stencil/core';

const pluralToSingular = {
  hours: 'hour',
  days: 'day',
  weeks: 'week',
  months: 'month',
};

@Component({
  tag: 'time-period-selector',
  styleUrl: 'time-period-selector.scss',
  scoped: true,
})
export class TimePeriodSelector {
  @Element() host!: HTMLElement;

  // The possible values for the time periods dropdown
  @Prop() timePeriods: string[] = ['hour', 'day', 'week', 'month'];

  /**
   * The default selected time period.
   */
  @Prop() defaultSelectedPeriod!: string;

  /**
   * The default selected number.
   */
  @Prop() defaultSelectedNumber!: number;

  /**
   * The currently selected time period
   */
  @State() selectedPeriod: string = this.defaultSelectedPeriod;

  /**
   * The currently selected number of the time period
   */
  @State() selectedNumber: number = this.defaultSelectedNumber;

  /*
   * The options for the number dropdown, to be calculated based on the selectedPeriod
   */
  @State() numberOptions: { label: string; value: string }[] = this.calculateOptions(this.defaultSelectedPeriod || 'hour').map(i => {
    return {
      label: i.toString(),
      value: i.toString(),
    };
  });

  /**
   * The options for the time period dropdown
   */
  @State() timePeriodOptions = this.timePeriods.map(period => {
    return {
      label: period,
      value: period,
    };
  });

  componentDidLoad() {
    this.selectedNumber = this.defaultSelectedNumber;
    this.selectedPeriod = this.defaultSelectedPeriod;
    const period = pluralToSingular[this.selectedPeriod] ?? this.selectedPeriod;
    const numberOptions = this.calculateOptions(period);
    this.numberOptions = numberOptions.map(i => {
      return {
        label: i.toString(),
        value: i.toString(),
      };
    });
  }

  @Event() timePeriodChanged!: EventEmitter<{ number: number; period: string }>;

  private calculateOptions(period: string): number[] {
    switch (period) {
      case 'hour':
        return Array.from({ length: 23 }, (_, i) => i + 1);
      case 'minute':
        return Array.from({ length: 13 }, (_, i) => i * 5);
      case 'day':
        return Array.from({ length: 30 }, (_, i) => i + 1);
      case 'week':
        return Array.from({ length: 4 }, (_, i) => i + 1);
      case 'month':
        return Array.from({ length: 12 }, (_, i) => i + 1);
      default:
        return [];
    }
  }

  @Listen('inputOptionChanged')
  inputOptionChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('time-period-selector', 'inputOptionChangedHandler', event.detail);
    const { value, name } = event.detail;
    if (name === 'time-number') {
      this.selectedNumber = value ? parseInt(value) : this.defaultSelectedNumber;
    }
    const selected = {
      number: this.selectedNumber,
      period: this.selectedPeriod,
    };
    this.timePeriodChanged.emit(selected);
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('time-period-selector', 'nylasFormDropdownChangedHandler', event.detail);
    const { value, name } = event.detail;
    if (name === 'time-period') {
      this.selectedPeriod = value;
      this.updateNumberOptionsAndSelectedNumber(value);
    } else if (name === 'time-number') {
      this.selectedNumber = parseInt(value);
    }
    const selected = {
      number: this.selectedNumber,
      period: this.selectedPeriod,
    };
    this.timePeriodChanged.emit(selected);
  }

  updateNumberOptionsAndSelectedNumber(period: string) {
    const numberOptions = this.calculateOptions(period);
    this.numberOptions = numberOptions.map(i => {
      return {
        label: i.toString(),
        value: i.toString(),
      };
    });
    this.selectedNumber = parseInt(this.numberOptions[0].value);
  }

  render() {
    return (
      <div class="time-period-selector">
        {typeof this.selectedNumber == 'number' && (
          <input-dropdown
            id="time-number"
            name={'time-number'}
            inputValue={this.selectedNumber.toString()}
            exportparts="id_dropdown: tps__number-dropdown, id_dropdown-input: tps__number-dropdown-button, id_dropdown-content: tps__number-dropdown-content"
            options={this.numberOptions}
            defaultInputOption={this.numberOptions.find(i => i.value == this.selectedNumber.toString())}
          />
        )}
        {typeof this.selectedPeriod == 'string' && (
          <select-dropdown
            id="time-period"
            name={'time-period'}
            options={this.timePeriodOptions}
            exportparts="sd_dropdown: tps__period-dropdown, sd_dropdown-button: tps__period-dropdown-button, sd_dropdown-content: tps__period-dropdown-content"
            pluralizedLabel={this.selectedNumber > 1 ? 's' : ''}
            defaultSelectedOption={this.timePeriodOptions.find(i => i.value == this.selectedPeriod)}
            withSearch={false}
          />
        )}
      </div>
    );
  }
}
