import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'underline-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class UnderlineIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      // Underline icon svg
      <svg width={this.width} height={this.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 4V10C19 13.866 15.866 17 12 17C8.13401 17 5 13.866 5 10V4M8.5 4V10C8.5 13.2218 10.6766 15.9352 13.6395 16.7501M4 21H20M3 4L10.5 4M17 4L21 4"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
}
