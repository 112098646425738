@import '../../../common/styles/variables.scss';

.tooltip-container {
  position: relative;
  display: flex;
  @include default-css-variables;
}

.tooltip-content {
  visibility: hidden;
  background-color: var(--nylas-base-800);
  color: var(--nylas-base-0);
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 0.5rem 1rem;
  max-width: 200px;
  width: max-content;
}

/* Position-specific styles */
.tooltip-content.top {
  bottom: 0;
  left: 0;
  margin-bottom: 1.25rem;
  transform: translateX(-50%);
}

.tooltip-content.right {
  top: 50%;
  left: 0%;
  margin-left: 1.25rem;
  transform: translateY(-50%);
}

.tooltip-content.bottom {
  top: 0;
  left: 0;
  margin-top: 1.25rem;
  transform: translateX(-50%);
}

.tooltip-content.left {
  top: 50%;
  right: 0%;
  margin-right: 1.25rem;
  transform: translateY(-50%);
}

.tooltip-container:hover .tooltip-content,
.tooltip-container:focus-within .tooltip-content {
  visibility: visible;
  opacity: 1;
}

/* Arrow styles */
.tooltip-content::after {
  content: '';
  position: absolute;
  top: -0.75rem;
  left: 50%;
  transform: rotate(180deg);
  border-width: 6px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-content {
  &.top::after {
    top: 100%;
    left: 50%;
    transform: rotate(0deg);
  }

  &.bottom::after {
    top: -0.75rem;
    left: 50%;
    transform: rotate(180deg);
  }

  &.left::after {
    top: 47.5%;
    left: 100%;
    transform: rotate(270deg);
  }

  &.right::after {
    top: 47.5%;
    left: -0.75rem;
    transform: rotate(90deg);
  }
}
