import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'stop-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class StopIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      // Stop icon svg
      <svg width={this.width} height={this.height} viewBox="0 0 24 24" fill="auto" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4H20V20H4V4Z" stroke="auto" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    );
  }
}
