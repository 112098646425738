import { sanitize } from '@/utils/utils';
import { Component, h, Prop, State, Event, EventEmitter, Listen } from '@stencil/core';

/**
 * `textarea-component` allows users to enter multiline text.
 * It is ideal for larger inputs like comments or messages in a form.
 * This component is used in the scheduling form to input multiline text.
 *
 * @part tc__label - The label for the textarea.
 * @part tc__textarea - The textarea element.
 */
@Component({
  tag: 'textarea-component',
  styleUrl: 'textarea-component.scss',
  shadow: true,
})
export class TextareaComponent {
  /**
   * The name of the textarea, important for form submissions.
   */
  @Prop() name: string = 'textarea';

  /**
   * The default value of the textarea, appearing when the component first renders.
   */
  @Prop() defaultValue?: string;

  /**
   * The label for the textarea, displayed above it.
   */
  @Prop() label: string = '';

  /**
   * Placeholder text shown in the textarea when it is empty.
   */
  @Prop() placeholder: string = '';

  /**
   * Specifies if the textarea is required for form submission.
   * If true, an error message shows if left empty.
   */
  @Prop() required: boolean = false;

  /**
   * If true, the textarea cannot be edited by the user.
   */
  @Prop() readOnly: boolean = false;

  /**
   * Automatically focus the textarea when the component loads.
   */
  @Prop() autoFocus: boolean = false;

  /**
   * The maximum number of characters allowed in the textarea.
   */
  @Prop() maxLength: number = 255;

  /**
   * The type
   */
  @Prop() type: string = 'multi_line_text';

  /**
   * The content of the label's tooltip
   */
  @Prop() tooltip: string = '';

  /**
   * State for the current value of the textarea.
   */
  @State() value: string = '';

  /**
   * State for managing the display of any error messages.
   */
  @State() error: string = '';

  /**
   * Event emitted when the value of the textarea changes.
   * Useful for parent components to capture user input.
   */
  @Event() nylasFormInputChanged!: EventEmitter<{ value: string; name: string; error: string; label: string; type: string }>;

  /**
   * Lifecycle method that runs before the component loads.
   * It sets the initial value and cleans it using sanitize-html.
   */
  componentDidLoad() {
    this.value = sanitize(this.defaultValue || '');
  }

  // Event listeners
  /**
   * Listen for the bookingFormSubmitted event to validate the input value when the form is submitted.
   */
  @Listen('bookingFormSubmitted', { target: 'document' })
  handleBookingFormSubmitted(event: CustomEvent) {
    this.validate(this.value);
    if (this.error) {
      event.preventDefault();
    }
  }

  @Listen('triggerValidation', { target: 'document' })
  handletriggerValidation(event: CustomEvent) {
    this.validate(this.value);
    if (this.error) {
      event.preventDefault();
    }
  }

  /**
   * Handles user input in the textarea, updating the value and emitting an event.
   */
  handleInput(e: Event) {
    const inputElement = e.target as HTMLTextAreaElement;
    this.value = sanitize(inputElement.value);
    this.nylasFormInputChanged.emit({
      value: this.value,
      name: this.name,
      error: this.error,
      label: this.label,
      type: this.type,
    });
  }

  /**
   * Validates the textarea value based on the required prop and maxLength.
   * If the value is invalid, an error message is displayed.
   */
  validate(value: string) {
    if (this.required && !value) {
      this.error = `${this.label} is required.`;
    } else if (value.length > this.maxLength) {
      this.error = `${this.label} cannot exceed ${this.maxLength} characters.`;
    } else {
      this.error = '';
    }
  }

  /**
   * Renders the component UI including the label, textarea, and any error messages.
   */
  render() {
    return (
      <label part="tc__label" class={{ error: !!this.error }}>
        <p>
          <span class="label">{this.label}</span>
          {this.required && <span class="required">*</span>}
          {this.tooltip && (
            <tooltip-component>
              <info-icon slot="tooltip-icon" />
              <span slot="tooltip-content">{this.tooltip}</span>
            </tooltip-component>
          )}
        </p>
        <textarea
          name={this.name}
          placeholder={this.placeholder}
          readOnly={this.readOnly}
          autoFocus={this.autoFocus}
          value={this.value}
          maxLength={this.maxLength}
          onInput={e => this.handleInput(e)}
          class={{ error: !!this.error }}
          part="tc__textarea"
        />
        {this.error && <span class="error help-text">{this.error}</span>}
      </label>
    );
  }
}
