@import '../../../common/styles/variables.scss';
@import '../../../common/mixins/inputs.scss';

:host {
  display: block;
  @include default-css-variables;
  width: 100%;
}

label {
  @include input-label;
  flex-direction: column;
  gap: 4px;
  font-family: var(--nylas-font-family);
  font-size: 16px;
  color: var(--nylas-base-800);

  p {
    margin: 0;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .error {
    color: var(--nylas-error);
  }
}

textarea {
  @include textfield;
  padding: 12px 16px;
  border-width: 1;
  resize: vertical;
  border-radius: 8px;
  font-family: var(--nylas-font-family);
  font-size: 16px;
  line-height: 24px;

  &::-webkit-resizer {
    display: none;
  }
}
