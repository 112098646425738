import { Component, Element, Prop, h } from '@stencil/core';

/**
 * The `tooltip-component` component is a UI component that displays a tooltip.
 *
 * @part tc__container - The tooltip container.
 * @part tc__content - The tooltip content.
 */
@Component({
  tag: 'tooltip-component',
  styleUrl: 'tooltip-component.scss',
  shadow: true,
})
export class TooltipComponent {
  @Element() el!: HTMLElement;
  /**
   * The position of the tooltip.
   */
  @Prop() position: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

  showTooltip(event: FocusEvent) {
    ((event.target as HTMLElement).nextElementSibling as HTMLElement)!.style.visibility = 'visible';
    ((event.target as HTMLElement).nextElementSibling as HTMLElement)!.style.opacity = '1';
  }

  hideTooltip(event: FocusEvent) {
    ((event.target as HTMLElement).nextElementSibling as HTMLElement)!.style.visibility = 'hidden';
    ((event.target as HTMLElement).nextElementSibling as HTMLElement)!.style.opacity = '0';
  }
  render() {
    return (
      <div class="tooltip-container" part="tc__container">
        <span class="info-icon" tabindex="0" onFocus={this.showTooltip.bind(this)} onBlur={this.hideTooltip.bind(this)}>
          <slot name="tooltip-icon">i</slot>
        </span>
        <span class={`tooltip-content ${this.position}`} part="tc__content">
          <slot name="tooltip-content">This is a help text.</slot>
        </span>
      </div>
    );
  }
}
