import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'warning-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class WarningIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '16';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.68551 1.35232L1.12678 13.2773C0.576954 14.277 1.3002 15.5002 2.4411 15.5002H15.5586C16.6995 15.5002 17.4227 14.277 16.8729 13.2773L10.3142 1.35232C9.74426 0.316153 8.2554 0.316157 7.68551 1.35232ZM8.99982 5.25016C9.41403 5.25016 9.74982 5.58595 9.74982 6.00016V10.0002C9.74982 10.4144 9.41403 10.7502 8.99982 10.7502C8.5856 10.7502 8.24982 10.4144 8.24982 10.0002V6.00016C8.24982 5.58595 8.5856 5.25016 8.99982 5.25016ZM9.74982 12.2502C9.74982 12.6644 9.41403 13.0002 8.99982 13.0002C8.5856 13.0002 8.24982 12.6644 8.24982 12.2502C8.24982 11.8359 8.5856 11.5002 8.99982 11.5002C9.41403 11.5002 9.74982 11.8359 9.74982 12.2502Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
